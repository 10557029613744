import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import listOfTimeZonesWithUtc from '../models/timezones-utc';
@Pipe({
  name: 'datewithTimeZone',
})
export class DatewithTimeZonePipe implements PipeTransform {
  transform(value: Date, arg: string, arg2: string) {
    const loggedInTimezone = listOfTimeZonesWithUtc.find(
      (eachTimeZone) =>
        eachTimeZone.timezone.toLowerCase() === arg?.toLowerCase()
    );
    let str = moment.utc(value).toLocaleString();
    str = new Date(str).toLocaleString('en-US', {
      timeZone: loggedInTimezone?.utc,
    });
    switch (arg2) {
      case 'date-time':
        return loggedInTimezone
          ? `${moment(str).format('MM/DD/YYYY hh:mm a')} (${
              loggedInTimezone?.shortFormTimeZone
            }) `
          : `${moment(value).format('MM/DD/YYYY hh:mm a')} (${
              loggedInTimezone?.shortFormTimeZone
            })`;
        break;
      case 'time':
        return loggedInTimezone
          ? `${moment(str).format('hh:mm a')} (${
              loggedInTimezone?.shortFormTimeZone
            }) `
          : `${moment(value).format('hh:mm a')} (${
              loggedInTimezone?.shortFormTimeZone
            })`;
        break;
      case 'date':
        return loggedInTimezone
          ? `${moment(str).format('MM/DD/YYYY')}  `
          : `${moment(value).format('MM/DD/YYYY')} `;
        break;
    }
  }
}